import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageView from "../components/imageView"
import useWindowSize from "../hooks/useWindowSize"
import QuickLink from "../components/layout/link"

// Rendered size:	2258 × 1582 px
// Rendered aspect ratio:	1129∶791
// Intrinsic size:	4744 × 2899 px
// Intrinsic aspect ratio:	4744∶2899

const IndexPage = () => {
  const [isOpen, setOpen] = React.useState(false)
  const windowSize = useWindowSize()

  const renderedImageHeight = windowSize.width * (2734 / 5468)

  const initialScale =
    renderedImageHeight < windowSize.height
      ? windowSize.height / renderedImageHeight
      : 1

  if (windowSize.height == undefined) {
    return <></>
  }
  return (
    <Layout>
      <Seo title="Facilities" />
      {/* <QuickLink
        links={[{ text: "View Legend", onClick: () => setOpen(true) }]}
      />
      {isOpen && (
        <Lightbox
          mainSrc="/images/legend.jpg"
          onCloseRequest={() => setOpen(false)}
        />
      )} */}
      <ImageView initialScale={initialScale}>
        <StaticImage
          objectFit="contain"
          className="object-contain md:object-contain min-h-screen h-full"
          src="../images/facilities.jpg"
          alt="Floor plan"
          quality={100}
          loading="eager"
          placeholder="blurred"
          breakpoints={[4961]}
          formats={["auto", "webp", "avif"]}
        />
      </ImageView>
    </Layout>
  )
}

export default IndexPage
